import React from "react"
import SEO from "components/seo";
import { Link } from "gatsby";

export default function FourOFour() {
  return (
    <>
      <SEO title="Page non trouvée" />
      <div>
        <p>Malheureusement cette page n'éxiste pas. <Link to="/">Rendez-vous à l'accueil.</Link></p>
      </div>
    </>
  );
}
